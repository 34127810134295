import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import NavLink from "../components/nav-link"

const MediaVideos = ({ pageContext, location }) => {
  const { group, index, first, last /*_pageCount*/ } = pageContext
  const previousUrl = index - 1 === 1 ? "/" : (index - 1).toString()
  const nextUrl = (index + 1).toString()
  const basePath = "/media"
  return (
    <Layout location={location}>
      <SEO title="Media"></SEO>
      {/* Media */}
      <section className="case-studies">
       
          <div className="container">
            <div className="row row--center">
              <div className="col col__11">
                <h1 className="title title--center">Media</h1>
                {group.map((node) => (
                 
                  <article key={node.id} className="case-box">
                    <a href={node.urlYoutube} target="_blank" rel="noopener noreferrer">
                      <figure className="case-box__image">
                      
                        <img src={node.image} alt="" />
                      
                      </figure>
                      <div className="case-box__data">
                        <h2 className="case-box__title">{node.title}</h2>
                        <p className="case-box__description">
                            {node.short}

                            <p style={{paddingTop: "0.5em"}}> {node.short1 }</p>
                        </p>
                      </div>
                    </a>
                  </article>
                  
                ))}
              </div>
            </div>
            <div className="row">
              <ul className="pagination">
                <li className="pagination__item current">
                  <NavLink
                    basePath={basePath}
                    test={first}
                    url={previousUrl}
                    text="< Go to Previous Page"
                  />
                </li>
                <li className="pagination__item">
                  <NavLink
                    basePath={basePath}
                    test={last}
                    url={nextUrl}
                    text="Go to Next Page >"
                  />
                </li>
              </ul>
            </div>
          </div>
       
      </section>
    </Layout>
  )
}

export default MediaVideos
