import React from "react"
import Link from "gatsby-link"

const NavLink = ({ text, test, url, basePath }) => {
  if (!test) {
    return <Link to={`/${basePath}/${url}`}>{text}</Link>
  } else {
    return null
  }
}

export default NavLink
